export default {
  // function
  setting: '点击设置',
  edit: '编辑',
  submitConfirm: '确认提交 cmd+s',
  close: '关闭 esc',
  fullScreen: '全屏',
  cancelFullScreen: '取消全屏',
  jsonIllegal: '非json格式',
  functionIllegal: '非function格式',
  objectIllegal: '非object格式',
  circularRef: '对象中出现循环引用的对象',
  formatError: '格式错误',
  saved: '已保存',
  // expression
  valueIllegal:
    '值类型为对象类型，与当前组件属性设置的控件类型不匹配，请在属性“代码编辑模式”下进行编辑',
  jsExpression: '请输入JS表达式',
  // Mixin
  input: '字符串Input',
  textarea: '多行字符串Textarea',
  expression: '变量控件Expression',
  monacoEditor: '编辑器MonacoEditor',
  numberPicker: '数字NumberPicker',
  bool: '布尔Switch',
  datePicker: '日期选择DatePicker',
  select: '下拉选择Select',
  radio: '单项选择RadioGroup',
  date: '日期选择DatePicker',
  dateYear: '年选择DatePicker',
  dateMonth: '月选择DatePicker',
  dateRange: '日期区间选择DatePicker',
  list: '数组List',
  object: '对象ObjectButton',
  reactNode: '节点类型ReactNode',
  typeError: 'Minix组件属性Types配置错误，存在不支持类型[{type}]，请检查组件属性配置',
};
